import React, { useState, useCallback, Fragment } from 'react';
import ToastContext from './ToastContext';
import Toast from './Toast';
import PropTypes from 'prop-types';

const DEFAULT_OPTIONS = {
  message: '',
  delay: 5000,
  bg: ''
};
const buildOptions = (defaultDelay, options) => {

  const delay = (defaultDelay || DEFAULT_OPTIONS.delay) || (options.delay);

  return {
    ...DEFAULT_OPTIONS,
    ...options,
    delay
  };
};
const ToastProvider = ({ children, delay }) => {
  const [options, setOptions] = useState({ ...DEFAULT_OPTIONS, ...delay });
  const [open, setOpen] = useState(false);

  const toast = useCallback((options = {}) => {
    return new Promise((resolve) => {
      setOptions(buildOptions(delay, options));
      resolve(setOpen(true));
    });
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);

  }, []);
  return (
    <>
      <ToastContext.Provider value={toast}>
        {children}
      </ToastContext.Provider>
      <Toast
        open={open}
        options={options}
        onClose={handleClose}
      />
    </>
  );
};
ToastProvider.propTypes = {
  children: PropTypes.element.isRequired
};
export default ToastProvider;