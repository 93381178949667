import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.scss";
import * as yup from "yup";
import { useFormik } from "formik";

const Dashboard = () => {
  const initialValues = {
    startDate: '',
    endDate: '',
    game: ''
  };

  const { handleSubmit, handleChange, values, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async () => {
      console.log("values", values);
    },
  });
  return (
    <main id="dashboard">
      <div className='d-flex mt-4' style={{ marginLeft: '10px' }}>
        <div className='dashboard-card text-white bg-primary mb-3'>
          {/* <div className="card-header">User</div> */}
          <div className="card-body">
            <h5 className="card-title">AVAILABLE POINTS</h5>
            <p className="card-text">1</p>
          </div>
        </div>
        <div className='dashboard-card text-white mb-3' style={{background:"#f94a4a"}}>
          {/* <div className="card-header">User</div> */}
          <div className="card-body">
            <h5 className="card-title">TOTAL ONLINE USERS</h5>
            <p className="card-text">0</p>
          </div>
        </div>
        <div className='dashboard-card text-white bg-warning mb-3'>
            <div className="card-body  bg-warning" style={{display:'flex' ,justifyContent:'space-between', padding:0}}>
              <div>
              <h5 className="card-title">PC USERS</h5>
              <p className="card-text">0</p>
              </div>
              <div>
              <h5 className="card-title">MOBILE USERS</h5>
              <p className="card-text">0</p>
              </div>
            </div>
           
        </div>
      </div>

      <Container className="admin_padding">
        <div className="admin_form">
          <div className="detail-heading">
            <h6 className="text-white">Datepicker</h6>
          </div>
          <Row className="admin_add_form">
            <Col>
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>
                        START DATE
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="startDate"
                        value={values.startDate}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik02">
                      <Form.Label>
                        END DATE
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="endDate"
                        value={values.endDate}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="validationFormik03">
                      <Form.Label>
                        SELECT GAME <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        name="game"
                        value={values.game}
                        onChange={handleChange}
                      >
                        <option value=''>Select Game</option>
                        <option value='Cricket'>Cricket</option>
                        <option value='Football'>Football</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <div style={{ display: "flex", justifyContent: "space-between" }} className="footer-btns">
                  <Button type="submit" className="input_file_button">
                    SUBMIT
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </main >
  );
};

export default Dashboard;

