import axios from "axios";
import { getUserToken, removeUserData } from "./cookie";

let apiAdminInstance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL
});

apiAdminInstance.interceptors.request.use(
  async config => {
    // config.headers.Authorization = `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoiNjM3MzQzMTZkYzE5YTBiMDUwOGEzYjBkIiwiaWF0IjoxNjc1MDU2NzM3LCJleHAiOjE2NzU2NjE1Mzd9.E-f3hwRpr29EB__6zCSID4lDo90b66DHO81YJXX3G5U"}`;
    // ngrok 
    config.headers['ngrok-skip-browser-warning'] = 'true';
    const session = await getUserToken()
    if (session) {
      config.headers.Authorization = `Bearer ${session}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

apiAdminInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  error => {
    const { response } = error;
    console.log("throw", response);
    if (response.status === 401) {
      removeUserData();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);


export const apiAdminConfig = apiAdminInstance;