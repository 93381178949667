import React from "react";
import { Container, Spinner } from "react-bootstrap";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import { Space, Table, Popconfirm, Button, Image } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import useToast from "../../provider/Toast/useToast";
import { apiAdminConfig } from "../../utils/api";
import { getRole } from "../../utils/cookie";

const Partner = () => {
    const [partnerList, setPartnerList] = React.useState([]);
    const [userRole, setUserRole] = React.useState(getRole())
    const [loading, setLoading] = React.useState(false);

    const toast = useToast();

    const navigate = useNavigate();

    const getpartnerList = async () => {
        setLoading(true)
        await apiAdminConfig.get(`users/get/partner`).then((response) => {
            if (response?.status == 200) {
                setPartnerList(response?.data?.results)
                setLoading(false)
            }
        }).catch((error) => {
            setLoading(false)
            console.log("partnerListError", error);
        })
    }
    React.useEffect(() => {
        getpartnerList();
    }, [])

    const onDelete = async (id) => {
        await apiAdminConfig.delete(`users/delete/${id}`).then((response) => {
            console.log("remove_sponser", response)
            if (response?.status === 200) {
                toast({
                    message: 'Partner Delete successfully',
                    bg: 'success'
                });
                getpartnerList();
            } else {
                toast({
                    message: response?.data?.message,
                    bg: 'danger'
                });
            }
        }).catch((error) => {
            const { response } = error
            toast({
                message: response?.data?.message,
                bg: "danger",
            });
        })
    }

    const columns = [
        {
            title: 'Sr no',
            dataIndex: '_id',
            key: 'Srno',
            render: (_, record, index) => {
                return (
                    <>
                        {index + 1}
                    </>
                )
            }
        },
        {
            title: 'User Name',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'Email',
            render: text => text || '-'
        },
        {
            title: 'Phone No',
            dataIndex: 'phone',
            key: 'Phone No',
            render: text => text || '-'
        },
        ...(userRole === "superAdmin" ? [

            {
                title: 'Admin Name',
                dataIndex: 'adminName',
                key: 'Admin Name',
                render: (_, record) => {
                    return (
                        <>
                            {record?.admin?.userName || "N/A"}
                        </>
                    )
                }
            }
        ] : []),
        {
            title: "Action",
            dataIndex: "action",
            key: 'Action',
            render: (_, record) => (
                <Space>
                    <Button
                        type="primary"
                        title="Edit"
                        style={{ padding: "2px 4px" }}
                        onClick={() => {
                            navigate(`/partner/form`, { state: { data: record } })
                        }}
                    >
                        {" "}
                        <strong>
                            {" "}
                            <EditOutlined
                                style={{
                                    fontWeight: "900",
                                    fontSize: "16px",
                                    verticalAlign: "middle",
                                }}
                            />{" "}
                        </strong>{" "}
                    </Button>

                    <Popconfirm
                        title="Are you sure want to delete ?"
                        key={record.id}
                        onConfirm={() => onDelete(record.id)}
                    >
                        <Button danger type="primary" title="Delete" style={{ padding: "4px 8px" }}>
                            <strong>
                                <DeleteOutlined
                                    style={{
                                        fontWeight: "900",
                                        fontSize: "16px",
                                        verticalAlign: "middle",
                                    }}
                                />
                            </strong>
                        </Button>

                    </Popconfirm>
                    <Button danger type="primary" title="View" style={{ padding: "4px 8px" }} onClick={() => {
                        navigate(`/partner/view`, { state: { data: record } })
                    }}>
                        <strong>
                            <EyeOutlined
                                style={{
                                    fontWeight: "900",
                                    fontSize: "16px",
                                    verticalAlign: "middle",
                                }}
                            />
                        </strong>
                    </Button>
                </Space>
            ),
        }
    ]




    return (
        <main id="partnerLists">
            <Container className="admin_padding">
                <div className="admin_padding_table">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "14px 0",
                            alignItems: "center",
                            padding: "0 10px",
                        }}
                        className="table-hader-container"
                    >
                        <div>
                            <h3 className="table-hader-name">Partner</h3>
                        </div>
                        <Link to="/partner/form" className="page_add_btn">
                            Add New
                        </Link>
                    </div>
                    <div className="table-container">
                        {loading && (
                            <div className="spinner-overlay">
                                <Spinner animation="border" />
                            </div>
                        )}
                        {
                            loading ?
                                <Table dataSource={partnerList} scroll={{ x: 525 }} />
                                :
                                <Table dataSource={partnerList} columns={columns} scroll={{ x: 525 }} />

                        }
                    </div>
                </div>
            </Container>
        </main>
    );
};

export default Partner;
