import React, { useEffect, useState } from "react";
import "./sidebar.scss";
import { Outlet, Link } from "react-router-dom";
import {
  UserOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  UserAddOutlined,
  TeamOutlined,
  DollarCircleOutlined,
  ShareAltOutlined,
  UsergroupAddOutlined,
  SolutionOutlined

} from "@ant-design/icons";
import { Menu, Layout, Avatar, Button, Popover } from "antd";
import { useAuth } from "../Component/hook/auth";
import { CloseOutlined } from '@ant-design/icons';

const { Header, Sider, Content } = Layout;

function Sidebar() {
  const [collapsed, setCollapsed] = useState(window.innerWidth <= 767);
  const [isWindowShow, setIsWindowShow] = useState(window.innerWidth >= 767);

  const { logout } = useAuth();

  const data = localStorage.getItem('userDetail');
  const userData = JSON.parse(data);
  const userRole = userData?.role;
  const userName = userData?.userName?.charAt(0)?.toUpperCase()+ userData?.userName?.slice(1).toLowerCase();

  useEffect(() => {
    const handleResize = () => {
      setIsWindowShow(window.innerWidth >= 767)
      setCollapsed(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const content = (
    <div>
      <Button className="admin-topbar-btn" onClick={logout}>
        <LogoutOutlined />
        LogOut
      </Button>
    </div>
  );

  const iconStyle = {
    color: '#ffffff',
    backgroundColor: '#0033cc',
    borderRadius: '50%',
    padding: '8px'
  };

  return (
    <>

      {
        collapsed ?


          <Layout>
            <Sider
              trigger={null}
              collapsible
              collapsed={false}
              width={0}
              style={{
                position: "fixed",
                height: "100vh",
                zIndex: "999",
                left: "0",
              }}
            >
              <button
                title="Toggle Menu"
                className="sidebarWrapperButton"
                onClick={toggleCollapsed}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>

              <div className="sidebarLogo">
               {isWindowShow ?  <h4>GOA ADMIN</h4> : <h4 style={{ color: "rgb(0, 0, 0)" , paddingLeft: "22px"}}>{userName}</h4>}
              </div>

              <Menu mode="inline" defaultOpenKeys={['menu']}>
                <Menu.Item key="Dashboard" icon={<HomeOutlined style={iconStyle}/>}>
                  <span>Dashboard</span>
                  <Link to="/" />
                </Menu.Item>

                <Menu.SubMenu title="Members" key="menu" icon={<MenuFoldOutlined style={iconStyle}/>}>
                  {userRole === "superAdmin" && (
                    <Menu.Item key="Register" icon={<UserAddOutlined style={iconStyle}/>}>
                      <span>Admin</span>
                      <Link to="/register" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "superAdmin") && (
                    <Menu.Item key="Partner" icon={<TeamOutlined style={iconStyle}/>}>
                      <span>Partner</span>
                      <Link to="/partner" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Distributor" icon={<DollarCircleOutlined style={iconStyle}/>}>
                      <span>Distributor</span>
                      <Link to="/distributor" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "distributor" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Sub Distributor" icon={<ShareAltOutlined style={iconStyle}/>}>
                      <span>Sub Distributor</span>
                      <Link to="/sub-distributor" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "subDistributor" || userRole === "partner" || userRole === "distributor" || userRole === "superAdmin") && (
                    <Menu.Item key="Agent" icon={<UsergroupAddOutlined style={iconStyle}/>}>
                      <span>Agent</span>
                      <Link to="/agent" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor" || userRole === "superAdmin") && (
                    <Menu.Item key="User" icon={<SolutionOutlined style={iconStyle}/>}>
                      <span>User</span>
                      <Link to="/user" />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>

                {userRole === "admin" && (
                  <>
                    <Menu.Item key="Game" icon={<HomeOutlined style={iconStyle}/>}>
                      <span>Game</span>
                      <Link to="/game" />
                    </Menu.Item>
                    <Menu.Item key="Commission" icon={<HomeOutlined style={iconStyle}/>}>
                      <span>Commission</span>
                      <Link to="/commission" />
                    </Menu.Item>
                  </>
                )}
              </Menu>
            </Sider>

            <Layout style={{ marginLeft: 0 }}>
              <Header style={{ position: "fixed", top: 0, zIndex: 1, width: "100%" }}>
                <div className="topbar">
                  <div className="topbarWrapper d-flex justify-content-end">
                    <div className="topRight">
                      <div className="topbar-text">
                        <h6>{userData?.userName?.toUpperCase()}</h6>
                      </div>
                      <div className="topbarIcons">
                        <Popover content={content} trigger="hover">
                          <Avatar
                            className="admin-avatar"
                            title={userData?.name?.toUpperCase()}
                            icon={<UserOutlined />}
                          />
                        </Popover>
                      </div>
                    </div>
                  </div>
                </div>
              </Header>

              <Content style={{ marginTop: 60 }}>
                <Outlet />
              </Content>
            </Layout>
          </Layout>
          :
          <Layout>
            <Sider
              trigger={null}
              collapsible
              collapsed={false}
              width={240}
              style={{
                position: "fixed",
                height: "100vh",
                zIndex: "999",
                left: "240",
              }}
            >
              {
                isWindowShow &&
                <button
                  title="Toggle Menu"
                  className="sidebarWrapperButton"
                  onClick={toggleCollapsed}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              }

              <div className="sidebarLogo d-flex justify-content-between align-items-center">

                {/* <h4 style={{ color: "rgb(0, 0, 0)", paddingLeft: "22px" }}>GOA ADMIN</h4> */}
                {isWindowShow ?  <h4 style={{ color: "rgb(0, 0, 0)", paddingLeft: "22px" }}>GOA ADMIN</h4> : <h4 style={{ color: "rgb(0, 0, 0)" , paddingLeft: "22px"}}>{userName}</h4>}
                {
                  !isWindowShow
                  &&
                  <button
                    title="Toggle Menu"
                    onClick={toggleCollapsed}
                  //   style={{
                  //     display: "flex",
                  //     alignItems: "center",
                  //     background: "none",
                  //     border: "none",
                  //     padding: 0,
                  //     cursor: "pointer",
                  //      width:'20px',
                  //     //  height:'20px'
                  // }}
                  // className="d-flex align-center"
                  className="close-button"
                  >

                    {/* <CloseOutlined style={{ color: "rgb(0, 0, 0)" ,paddingRight:"10px" }} className="close-icon"/> */}
                    <CloseOutlined style={{ color: "rgb(0, 0, 0)" ,paddingRight:"10px" , fontSize: "20px"}} />
                   
                    {/* <span></span> */}
                    {/* <span></span> */}
                  </button>
                }
              </div>

              <Menu mode="inline"  defaultOpenKeys={['menu']}>
                <Menu.Item key="Dashboard" icon={<HomeOutlined style={iconStyle}/>}>
                  <span>Dashboard</span>
                  <Link to="/" />
                </Menu.Item>

                <Menu.SubMenu title="Members" key="menu" icon={<MenuFoldOutlined style={iconStyle}/>}>
                  {userRole === "superAdmin" && (
                    <Menu.Item key="Register" icon={<UserAddOutlined style={iconStyle}/>}>
                      <span>Admin</span>
                      <Link to="/register" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "superAdmin") && (
                    <Menu.Item key="Partner" icon={<TeamOutlined style={iconStyle}/>}>
                      <span>Partner</span>
                      <Link to="/partner" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Distributor" icon={<DollarCircleOutlined style={iconStyle}/>}>
                      <span>Distributor</span>
                      <Link to="/distributor" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "distributor" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Sub Distributor" icon={<ShareAltOutlined style={iconStyle}/>}>
                      <span>Sub Distributor</span>
                      <Link to="/sub-distributor" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "subDistributor" || userRole === "partner" || userRole === "distributor" || userRole === "superAdmin") && (
                    <Menu.Item key="Agent" icon={<UsergroupAddOutlined style={iconStyle}/>}>
                      <span>Agent</span>
                      <Link to="/agent" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor" || userRole === "superAdmin") && (
                    <Menu.Item key="User" icon={<SolutionOutlined style={iconStyle}/>}>
                      <span>User</span>
                      <Link to="/user" />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>

                {userRole === "admin" && (
                  <>
                    <Menu.Item key="Game" icon={<HomeOutlined style={iconStyle}/>}>
                      <span>Game</span>
                      <Link to="/game" />
                    </Menu.Item>
                    <Menu.Item key="Commission" icon={<HomeOutlined style={iconStyle}/>}>
                      <span>Commission</span>
                      <Link to="/commission" />
                    </Menu.Item>
                  </>
                )}
              </Menu>
            </Sider>

            <Layout className="site-layout"
            >
              <Header style={{ position: "fixed", top: 0, zIndex: 1, width: "100%" }}>
                <div className="topbar">
                  <div className="topbarWrapper d-flex justify-content-end " >
                    <div className="topRight" style={{ paddingRight: isWindowShow ? "250px" : 0 }}>
                      <div className="topbar-text">
                        <h6>{userData?.userName?.toUpperCase()}</h6>
                      </div>
                      <div className="topbarIcons">
                        <Popover content={content} trigger="hover">
                          <Avatar
                            className="admin-avatar"
                            title={userData?.name?.toUpperCase()}
                            icon={<UserOutlined />}
                          />
                        </Popover>
                      </div>
                    </div>
                  </div>
                </div>
              </Header>

              <Content style={{ marginTop: 60 }}>
                <Outlet />
              </Content>
            </Layout>
          </Layout>

      }
    </>

  );
}

export default Sidebar;
