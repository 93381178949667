import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../style.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import useToast from "../../../provider/Toast/useToast";
import { apiAdminConfig } from "../../../utils/api";

const RegisterFormModel = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams()
  const isView = params.id == "view" ? true : false
  const toast = useToast();
  const isEditMode = !!location?.state?.data; // Check if we are in edit mode

  const [initialValues, setInitialValues] = useState({
    userName: '',
    email: '',
    password: '',
    phone: '',
  });

  useEffect(() => {
    if (isEditMode) {
      setInitialValues(location?.state?.data);
    }
  }, [location, isEditMode]);

  const validationSchema = () => yup.object().shape({
    userName: yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('User Name is required'),
    password: isEditMode
      ? yup.string().nullable()
      : yup.string()
        .min(4, 'Minimum 4 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required')
  });


  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async () => {
      values['phone'] = values.phone.toString();



      if (isEditMode) {
        await apiAdminConfig.put(`users/update/${location?.state?.data?.id}`, values).then((response) => {
          if (response?.status === 200) {
            navigate('/register');
            toast({
              message: 'Admin updated successfully',
              bg: 'success'
            });
          } else {
            toast({
              message: response?.data?.message,
              bg: 'danger'
            });
          }
        }).catch((error) => {
          const { response } = error;
          toast({
            message: response?.data?.message,
            bg: "danger",
          });
        });

      } else {

        await apiAdminConfig.post(`auth/admin/register`, values).then((response) => {
          if (response?.status === 201) {
            navigate('/register');
            toast({
              message: 'Admin registered successfully',
              bg: 'success'
            });
          } else {
            toast({
              message: response?.data?.message,
              bg: 'danger'
            });
          }
        }).catch((error) => {
          const { response } = error;
          toast({
            message: response?.data?.message,
            bg: "danger",
          });
        });
      }
    },
  });

  return (
    <main id="events">
      <Container className="admin_padding">
      <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // margin: "14px 0",
              alignItems: "center",
            }}
            className="table-hader-container"
          >
            <div>
              <h3 className="table-hader-name">{!isView ? (isEditMode ? 'EDIT ADMIN' : 'CREATE ADMIN') : 'View Admin'}</h3>
            </div>
            <Link to="/register" className="page_add_btn">
              Back
            </Link>
          </div>
        <div className="admin_form">
        <div className="detail-heading">
              <h6 className="text-white">ENTER DETAILS</h6>
            </div>

          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "14px 0",
              alignItems: "center",
            }}
            className="table-hader-container"
          >
            <div>
              <h3 className="table-hader-name">{!isView ? (isEditMode ? 'EDIT ADMIN' : 'CREATE ADMIN') : 'View Admin'}</h3>
            </div>
            <Link to="/register" className="page_add_btn">
              Back
            </Link>
          </div> */}
          <Row className="admin_add_form">
            <Col>
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>
                      User Name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="userName"
                        placeholder="Enter User Name"
                        value={values.userName}
                        onChange={handleChange}
                        disabled={isView}
                        isInvalid={!!errors.userName && touched.userName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.userName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>
                        Email
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Enter Email"
                        value={values.email}
                        disabled={isView}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>
                        Phone No
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="phone"
                        placeholder="Enter Phone No"
                        value={values.phone}
                        disabled={isView}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  {
                    !isView && (<Col>
                      <Form.Group controlId="validationFormik01">
                        <Form.Label>
                          Password
                          {!isEditMode && <span className="text-danger">*</span>}
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder="Enter Password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password && touched.password}
                        // isInvalid={!!errors.password && touched.password}
                        // placeholder={isEditMode ? 'Leave blank to keep unchanged' : ''}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>)
                  }
                </Row>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="footer-btns"
                >
                  {
                    !isView &&
                    <Button type="submit" className="input_file_button">
                      {isEditMode ? 'EDIT ADMIN' : 'CREATE ADMIN'}
                    </Button>
                  }

                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </main>
  );
};

export default RegisterFormModel;
