import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Sidebar from "../LayoutAdmin/Sidebar";
import Login from "../Pages/login";
import Partner from "../Pages/Partner";
import PartnerFormModel from "../Pages/Partner/PartnerFormModel";
import Distributor from "../Pages/Distributor";
import DestributorFormModel from "../Pages/Distributor/DistributorFormModel";
import SubDestributor from "../Pages/SubDistributor";
import SubDestributorFormModel from "../Pages/SubDistributor/SubDistributorFormModel";
import Agent from "../Pages/Agent";
import UserFormModel from "../Pages/User/UserFormModel";
import User from "../Pages/User";
import Register from "../Pages/Register";
import RegisterFormModel from "../Pages/Register/RegisterFormModel";
import AgentFormModel from "../Pages/Agent/AgentFormModel";
import Dashboard from "../Pages/DashBoard";
import Game from "../Pages/Game";
import GameFormModel from "../Pages/Game/GameFormModel";
import Commission from "../Pages/Commission";
import CommissionFormModel from "../Pages/Commission/CommissionFormModel";
import RegisterViewModel from "../Pages/Register/RegisterViewModel";
import PartnerViewModel from "../Pages/Partner/PartnerViewModel";
import DestributorViewModel from "../Pages/Distributor/DistributorViewModel";
import SubDestributorViewModel from "../Pages/SubDistributor/SubDistributorViewModel";
import AgentViewModel from "../Pages/Agent/AgentViewModel";
import UserViewModel from "../Pages/User/UserViewModel";
import { Error } from "../Pages/Error";

function AdminRoute() {
  const data = localStorage.getItem('userDetail')
  const userData = JSON.parse(data)
  const userRole = userData?.role;


  return (
    <Routes>
      <Route path="/" element={<Sidebar />}>
        <Route index element={<Dashboard />} />
        {(userRole == "superAdmin") &&
          <>
            <Route path="/register" element={<Register />} />
            <Route path="/register/form" element={<RegisterFormModel />} />
            <Route path="/register/view" element={<RegisterViewModel />} />
          </>}
        {(userRole == "admin" || userRole == "superAdmin") &&
          <>
            <Route path="/partner" element={<Partner />} />
            <Route path="/partner/form" element={<PartnerFormModel />} />
            <Route path="/partner/view" element={<PartnerViewModel />} />


            <Route path="/game" element={<Game />} />
            <Route path="/game/form" element={<GameFormModel />} />
            <Route path="/commission" element={<Commission />} />
            <Route path="/commission/form" element={<CommissionFormModel />} />
          </>}
        {(userRole == "admin" || userRole == "partner" || userRole == "superAdmin") &&
          <>
            <Route path="/distributor" element={<Distributor />} />
            <Route path="/distributor/form" element={<DestributorFormModel />} />
            <Route path="/distributor/view" element={<DestributorViewModel />} />
          </>
        }
        {(userRole == "admin" || userRole == "distributor" || userRole == "partner" || userRole == "superAdmin") &&
          <>
            <Route path="/sub-distributor" element={<SubDestributor />} />
            <Route path="/sub-distributor/form" element={<SubDestributorFormModel />} />
            <Route path="/sub-distributor/view" element={<SubDestributorViewModel />} />
          </>
        }
        {(userRole == "admin" || userRole == "subDistributor" || userRole == "distributor" || userRole == "partner" || userRole == "superAdmin") &&
          <>
            <Route path="/agent" element={<Agent />} />
            <Route path="/agent/form" element={<AgentFormModel />} />
            <Route path="/agent/view" element={<AgentViewModel />} />
          </>
        }
        {(userRole == "admin" || userRole == "agent" || userRole == "partner" || userRole == "distributor" || userRole == "subDistributor" || userRole == "superAdmin") &&
          <>
            <Route path="/user" element={<User />} />
            <Route path="/user/form" element={<UserFormModel />} />
            <Route path="/user/view" element={<UserViewModel />} />
          </>
        }
        
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path='/error/404' element={<Error />} />

        <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
  );
}

export default AdminRoute;
