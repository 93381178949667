import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../style.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import useToast from "../../../provider/Toast/useToast";
import { apiAdminConfig } from "../../../utils/api";

const CommissionFormModel = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const isEditMode = !!location?.state?.data; // Check if we are in edit mode

  const [initialValues, setInitialValues] = useState({
    role: '',
    commission: '',
  });

  useEffect(() => {
    if (isEditMode) {
      setInitialValues(location?.state?.data);
    }
  }, [location, isEditMode]);

  const validationSchema = yup.object().shape({
    role: yup.string()
      .required('Role is required'),
    commission: yup.string()
      .required('Commission is required'),
  });

  const { handleSubmit, handleChange, values, errors, touched ,setFieldValue} = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async () => {
      if (isEditMode) {
        await apiAdminConfig.put(`commission/update/${location?.state?.data?.id}`, values).then((response) => {
          if (response?.status === 200) {
            navigate('/commission');
            toast({
              message: 'Commission updated successfully',
              bg: 'success'
            });
          } else {
            toast({
              message: response?.data?.message,
              bg: 'danger'
            });
          }
        }).catch((error) => {
          const { response } = error;
          toast({
            message: response?.data?.message,
            bg: "danger",
          });
        });

      } else {

        await apiAdminConfig.post(`commission/create`, values).then((response) => {
          if (response?.status === 201) {
            navigate('/commission');
            toast({
              message: 'Commission registered successfully',
              bg: 'success'
            });
          } else {
            toast({
              message: response?.data?.message,
              bg: 'danger'
            });
          }
        }).catch((error) => {
          const { response } = error;
          toast({
            message: response?.data?.message,
            bg: "danger",
          });
        });
      }
    },
  });

  return (
    <main id="events">
      <Container className="admin_padding">
        <div className="admin_add_form">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "14px 0",
              alignItems: "center",
            }}
            className="table-hader-container"
          >
            <div>
              <h3 className="table-hader-name">{isEditMode ? 'Edit Commission' : 'Add Commission'}</h3>
            </div>
            <Link to="/commission" className="page_add_btn">
              Back
            </Link>
          </div>
          <Row>
            <Col>
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>
                        Select Role<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        type="text"
                        name="role"
                        value={values.role}
                        onChange={(e) => {
                          setFieldValue("role", e.target.value)
                        }}
                        isInvalid={!!errors.role && touched.role}
                      >
                        <option>Select Role</option>
                        <option value='distributor'>Distributor</option>
                        <option value='subDistributor'>Sub Distributor</option>
                        <option value='agent'>Agent</option>
                        <option value='user'>User</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.role}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>
                        Commission<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="commission"
                        value={values.commission}
                        placeholder="Enter Commission"
                        onChange={handleChange}
                        isInvalid={!!errors.commission && touched.commission}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.commission}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>


                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="footer-btns"
                >
                  <Button type="submit" className="input_file_button">
                    {isEditMode ? 'Update' : 'Save'}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </main>
  );
};

export default CommissionFormModel;
