import React from "react";
import { Container } from "react-bootstrap";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import { Space, Table, Popconfirm, Button, Image } from 'antd';
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import useToast from "../../provider/Toast/useToast";
import { apiAdminConfig } from "../../utils/api";


const Commission = () => {
    const [commissionList, setCommissionList] = React.useState([]);
    const toast = useToast();

    const navigate = useNavigate();

    const getcommissionList = async () => {
        await apiAdminConfig.get(`commission/get`).then((response) => {
            console.log("222",response)
            if (response?.data?.result?.length > 0) {
                setCommissionList(response?.data?.result)
            }
        }).catch((error) => {
            console.log("commissionListError", error);
        })
    }
    React.useEffect(() => {
        getcommissionList();
    }, [])

    const onDelete = async (id) => {

    }

    const columns = [
        {
            title: 'Sr no',
            dataIndex: '_id',
            key: 'Srno',
            width: '10%',
            render: (_, record, index) => {
                return (
                    <>
                        {index + 1}
                    </>
                )
            }
        },
        {
            title: 'Role',
            dataIndex: 'role',
            width: '70%',
            key: 'Role',
        },
        {
            title: 'Commission',
            dataIndex: 'commission',
            width: '70%',
            key: 'Commission',
        },
        {
            title: "Action",
            dataIndex: "action",
            width: '20%',
            key: 'Action',
            render: (_, record) => (
                <Space>
                    <Button
                        type="primary"
                        title="Edit"
                        style={{ padding: "2px 4px" }}
                        onClick={() => {
                            navigate(`/commission/form`, { state: { data: record } })
                        }}
                    >
                        {" "}
                        <strong>
                            {" "}
                            <EditOutlined
                                style={{
                                    fontWeight: "900",
                                    fontSize: "16px",
                                    verticalAlign: "middle",
                                }}
                            />{" "}
                        </strong>{" "}
                    </Button>
                </Space>
            ),
        }
    ]




    return (
        <main id="commissionLists">
            <Container className="admin_padding">
                <div className="admin_padding_table">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "14px 0",
                            alignItems: "center",
                            padding: "0 10px",
                        }}
                        className="table-hader-container"
                    >
                        <div>
                            <h3 className="table-hader-name">Commission</h3>
                        </div>
                        <Link to="/commission/form" className="page_add_btn">
                            Add New
                        </Link>
                    </div>
                    <Table dataSource={commissionList} columns={columns} scroll={{ x: 525 }} />
                </div>
            </Container>
        </main>
    );
};

export default Commission;

