import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import PropTypes from 'prop-types';

export const Index = ({ open, options, onClose }) => {
  const { delay, message, bg } = options;
  return (
    <ToastContainer style={{ zIndex: 1400 }} position='top-center' className="p-3">
      <Toast animation={true} bg={bg} onClose={() => onClose()} show={open} delay={delay} autohide>
        <Toast.Header className='text-black'>
          <strong className="me-auto">Alert Message</strong>
          <small>1 sec ago</small>
        </Toast.Header>
        <Toast.Body className='text-white'>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};
Index.propTypes = {
  options: PropTypes.any.isRequired

};
export default Index;
