import "../style.scss";
import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

const AgentViewModel = () => {
    const location = useLocation();
    const [agentDetail, setAgentDetail] = useState(null)
    useEffect(() => {
        setAgentDetail(location?.state?.data)
    }, [location]);

    return <>
        <main id="agentView">
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "14px 0",
                    alignItems: "center",
                }}
                className="table-hader-container"
            >
                <Link to="/agent" className="page_add_btn" style={{ marginLeft: "20px" }}>
                    Back
                </Link>
            </div>

            <div className='d-flex mt-3' >
                <div className='card text-white  mb-3'>
                    <div className="card-body">
                        <h5 className="card-title">Agent Detail</h5>
                        <hr></hr>
                        <p>
                            <span className="label">User Name</span>
                            <span className="colon">:</span>
                            <span className="value">{agentDetail?.userName || '-'}</span>
                        </p>
                        <p>
                            <span className="label">Email</span>
                            <span className="colon">:</span>
                            <span className="value">{agentDetail?.email || '-'}</span>
                        </p>
                        <p>
                            <span className="label">Phone No</span>
                            <span className="colon">:</span>
                            <span className="value">{agentDetail?.phone || '-'}</span>
                        </p>
                        {
                            agentDetail?.admin &&
                            <p>
                                <span className="label">Admin</span>
                                <span className="colon">:</span>
                                <span className="value">{agentDetail?.admin?.role || '-'}</span>
                            </p>
                        }
                        {
                            agentDetail?.partner &&
                            <p>
                                <span className="label">Partner</span>
                                <span className="colon">:</span>
                                <span className="value">{agentDetail?.partner?.role || '-'}</span>
                            </p>
                        }
                        {
                            agentDetail?.distributor &&
                            <p>
                                <span className="label">Distributor</span>
                                <span className="colon">:</span>
                                <span className="value">{agentDetail?.distributor?.role || '-'}</span>
                            </p>
                        }
                        {
                            agentDetail?.subDistributor &&
                            <p>
                                <span className="label">Sub Distributor</span>
                                <span className="colon">:</span>
                                <span className="value">{agentDetail?.subDistributor?.role || '-'}</span>
                            </p>
                        }
                    </div>
                </div>
            </div>
        </main >
    </>
}

export default AgentViewModel

















