import "../style.scss";
import React, { useState, useEffect } from "react";
import { useLocation ,Link} from "react-router-dom";

const RegisterViewModel =()=>{
    const location = useLocation(); 
    const [registerDetail,setRegisterDetail]=useState(null)
    useEffect(() => {
        setRegisterDetail(location?.state?.data)
    }, [location]);

    return <>
      <main id="partnerView">
      <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "14px 0",
              alignItems: "center",
            }}
            className="table-hader-container"
          >
            <Link to="/register" className="page_add_btn" style={{marginLeft:"20px"}}>
              Back
            </Link>
          </div>
          <div className='d-flex mt-3' >
        <div className='card text-white  mb-3'>
          <div className="card-body">
            <h5 className="card-title">Admin Detail</h5>
            <hr></hr>
            <p>
              <span className="label">User Name</span>
              <span className="colon">:</span>
              <span className="value">{registerDetail?.userName || '-'}</span>
            </p>
            <p>
              <span className="label">Email</span>
              <span className="colon">:</span>
              <span className="value">{registerDetail?.email || '-'}</span>
            </p>
            <p>
              <span className="label">Phone No</span>
              <span className="colon">:</span>
              <span className="value">{registerDetail?.phone || '-'}</span>
            </p>
          </div>
        </div>
      </div>
    </main >
    </>
}

export default RegisterViewModel

















