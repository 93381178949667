import { useNavigate } from 'react-router-dom';
import { removeUserData } from '../../utils/cookie';

export const useAuth = () => {
  const navigate = useNavigate();

  const logout = async () => {
    await removeUserData();
    navigate('/login')
  };

  return {
    logout
  };
};