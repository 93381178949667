import "../style.scss";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";


const DestributorViewModel = () => {
  const location = useLocation();
  const [destributorDetail, setDestributorDetail] = useState(null)
  useEffect(() => {
    setDestributorDetail(location?.state?.data)
  }, [location]);

  return <>
    <main id="destributorView">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "14px 0",
          alignItems: "center",
        }}
        className="table-hader-container"
      >
        <Link to="/distributor" className="page_add_btn" style={{ marginLeft: "20px" }}>
          Back
        </Link>
      </div>
      <div className='d-flex mt-3' >
        <div className='card text-white  mb-3'>
          <div className="card-body">
            <h5 className="card-title">Destributor Detail</h5>
            <hr></hr>
            <p>
              <span className="label">User Name</span>
              <span className="colon">:</span>
              <span className="value">{destributorDetail?.userName || '-'}</span>
            </p>
            <p>
              <span className="label">Email</span>
              <span className="colon">:</span>
              <span className="value">{destributorDetail?.email || '-'}</span>
            </p>
            <p>
              <span className="label">Phone No</span>
              <span className="colon">:</span>
              <span className="value">{destributorDetail?.phone || '-'}</span>
            </p>
            {
              destributorDetail?.admin &&
              <p>
                <span className="label">Admin</span>
                <span className="colon">:</span>
                <span className="value">{destributorDetail?.admin?.role || '-'}</span>
              </p>
            }
            {
              destributorDetail?.partner &&
              <p>
                <span className="label">Partner</span>
                <span className="colon">:</span>
                <span className="value">{destributorDetail?.partner?.role || '-'}</span>
              </p>
            }
          </div>
        </div>
      </div>
    </main >
  </>
}

export default DestributorViewModel

















