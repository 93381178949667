import Cookies from 'js-cookie';

export const setUserCookie = (user) => {
  localStorage.setItem('token', user?.token)
  localStorage.setItem('userDetail', JSON.stringify(user?.userData?.customerDetails))
  Cookies.set('AccessToken', JSON.stringify(user), { expires: 1, sameSite: 'strict', secure: false });
};

export const getUser = async () => {
  const user = await Cookies.get('AccessToken');
  if (user) {
    const userData = JSON.parse(user);
    return userData;
  }
  return null;
};

export const getUserToken = async () => {
  const user = await Cookies.get('AccessToken');
  if (user) {
    const userData = JSON.parse(user);
    return userData.token;
  }
  return null;
};

export const getUserName = async () => {
  const user = await Cookies.get('AccessToken');
  if (user) {
    const userData = JSON.parse(user);
    return userData.name;

  }
  return null;
};

export const removeUserData = async () => {
  localStorage.removeItem('token')
  await Cookies.remove('AccessToken');
};


export const getRole = () => {
  const data = localStorage.getItem('userDetail')
  const userData = JSON.parse(data)
  return userData?.role;
}