import React from "react";
import { Container ,Spinner} from "react-bootstrap";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import { Space, Table, Popconfirm, Button, Image } from 'antd';
import { DeleteOutlined, EditOutlined ,EyeOutlined } from "@ant-design/icons";
import useToast from "../../provider/Toast/useToast";
import { apiAdminConfig } from "../../utils/api";


const Register = () => {
    const [registerList, setRegisterList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const toast = useToast();

    const navigate = useNavigate();

    const getRegisterList = async () => {
        setLoading(true)
        await apiAdminConfig.get(`users/get?role=admin`).then((response) => {
            if (response?.status == 200) {
                setRegisterList(response?.data?.results)
                setLoading(false)
            }
        }).catch((error) => {
            setLoading(false)
            console.log("registerListError", error);
        })
    }
    React.useEffect(() => {
        getRegisterList();
    }, [])

    const onDelete = async (id) => {
        await apiAdminConfig.delete(`users/delete/${id}`).then((response) => {
            console.log("remove_sponser", response)
            if (response?.status === 200) {
                toast({
                    message: 'Admin Delete successfully',
                    bg: 'success'
                });
                getRegisterList();
            } else {
                toast({
                    message: response?.data?.message,
                    bg: 'danger'
                });
            }
        }).catch((error) => {
            const { response } = error
            toast({
                message: response?.data?.message,
                bg: "danger",
            });
        })
    }
    const columns = [
        {
            title: 'Sr no',
            dataIndex: '_id',
            key: 'Srno',
            width: '10%',
            render: (_, record, index) => {
                return (
                    <>
                        {index + 1}
                    </>
                )
            }
        },
        {
            title: 'User Name',
            dataIndex: 'userName',
            width: '50%',
            key: 'User Name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            width: '50%',
            key: 'Email',
            render: text => text || '-'
        },
        {
            title: 'Phone No',
            dataIndex: 'phone',
            width: '50%',
            key: 'Phone No',
            render: text => text || '-'
        },
        {
            title: "Action",
            dataIndex: "action",
            width: '20%',
            key: 'Action',
            render: (_, record) => (
                <Space>
                    <Button
                        type="primary"
                        title="Edit"
                        style={{ padding: "2px 4px" }}
                        onClick={() => {
                            navigate(`/register/form`, { state: { data: record } })
                        }}
                    >
                        {" "}
                        <strong>
                            {" "}
                            <EditOutlined
                                style={{
                                    fontWeight: "900",
                                    fontSize: "16px",
                                    verticalAlign: "middle",
                                }}
                            />{" "}
                        </strong>{" "}
                    </Button>

                    <Popconfirm
                        title="Are you sure want to delete ?"
                        key={record.id}
                        onConfirm={() => onDelete(record.id)}
                    >
                        <Button danger type="primary" title="Delete" style={{ padding: "4px 8px" }}>
                            <strong>
                                <DeleteOutlined
                                    style={{
                                        fontWeight: "900",
                                        fontSize: "16px",
                                        verticalAlign: "middle",
                                    }}
                                />
                            </strong>
                        </Button>
                    </Popconfirm>
                    <Button danger type="primary" title="View" style={{ padding: "4px 8px" }}  onClick={() => {
                            navigate(`/register/view`, { state: { data: record } })
                        }}>
                            <strong>
                                <EyeOutlined 
                                    style={{
                                        fontWeight: "900",
                                        fontSize: "16px",
                                        verticalAlign: "middle",
                                    }}
                                />
                            </strong>
                        </Button>
                </Space>
            ),
        }
    ]




    return (
        <main id="registerLists">
            <Container className="admin_padding">
                <div className="admin_padding_table">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "14px 0",
                            alignItems: "center",
                            padding: "0 10px",
                        }}
                        className="table-hader-container"
                    >
                        <div>
                            <h3 className="table-hader-name">Admin</h3>
                        </div>
                        <Link to="/register/form" className="page_add_btn">
                            Add New
                        </Link>
                    </div>
                    <div className="table-container">
                        {loading && (
                            <div className="spinner-overlay">
                                <Spinner animation="border" />
                            </div>
                        )}
                    {
                            loading ?
                                <Table dataSource={registerList} scroll={{ x: 525 }} />
                                :
                                <Table dataSource={registerList} columns={columns} scroll={{ x: 525 }} />

                        }
                </div>
                </div>
            </Container>
        </main>
    );
};

export default Register;

