import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../style.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import useToast from "../../../provider/Toast/useToast";
import { apiAdminConfig } from "../../../utils/api";
import { getRole } from "../../../utils/cookie";

const DestributorFormModel = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const params = useParams()
  const isView = params.id == "view" ? true : false
  const isEditMode = !!location?.state?.data;
  const [userRole, setUserRole] = React.useState(getRole())
  const [adminList, setAdminList] = useState([])
  const [partnerList, setPartnerList] = useState([])

  const [initialValues, setInitialValues] = useState({
    userName: '',
    email: '',
    password: '',
    phone: '',
    ...((userRole === 'superAdmin') && { admin: '', partner: '' }),
  });


  React.useEffect(() => {
    const data = localStorage.getItem('userDetail')
    const userData = JSON.parse(data);

    if (userData?.role == 'superAdmin') {
      getAdmin();
    }
  }, []);

  useEffect(() => {
    if (isEditMode || isView) {
      if (userRole == 'superAdmin') {
        getPartner(location?.state?.data?.admin?.id)
      }
      setInitialValues({
        ...location?.state?.data,
        admin: location?.state?.data?.admin?.id,
        partner: location?.state?.data?.partner?.id
      });
    }
  }, [location, isEditMode]);

  const getAdmin = async () => {
    await apiAdminConfig.get('users/get?role=admin').then((response) => {
      if (response?.data?.results?.length > 0) {
        setAdminList(response?.data?.results)
      }
    }).catch((error) => {
      console.log("certificateError", error);
    })
  };



  const validationSchema = yup.object().shape({
    userName: yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('User Name is required'),
    password: isEditMode
      ? yup.string().nullable()
      : yup.string()
        .min(4, 'Minimum 4 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    ...((userRole === 'superAdmin') && { admin: yup.string().required("Select Admin is required") }),

  });

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,


    onSubmit: async () => {
      values['phone'] = values.phone.toString();

      if (!values?.partner) delete values.partner


      if (isEditMode) {
        await apiAdminConfig.put(`users/update/${location?.state?.data?.id}`, values).then((response) => {
          if (response?.status === 200) {
            navigate('/distributor');
            toast({
              message: 'Distributor updated successfully',
              bg: 'success'
            });
          } else {
            toast({
              message: response?.data?.message,
              bg: 'danger'
            });
          }
        }).catch((error) => {
          const { response } = error;
          toast({
            message: response?.data?.message,
            bg: "danger",
          });
        });

      } else {

        await apiAdminConfig.post(`users/add-distributor`, values).then((response) => {
          if (response?.status === 201) {
            navigate('/distributor');
            toast({
              message: 'Distributor added successfully',
              bg: 'success'
            });
          } else {
            toast({
              message: response?.data?.message,
              bg: 'danger'
            });
          }
        }).catch((error) => {
          const { response } = error;
          toast({
            message: response?.data?.message,
            bg: "danger",
          });
        });
      }
    },
  });

  const getPartner = async (id) => {
    setFieldValue("partner", '')

    await apiAdminConfig.get(`users/list?userBy=partner&userId=${id}`).then((response) => {
      if (response?.data?.length > 0) {
        setPartnerList(response?.data)
      } else {
        setPartnerList([])
      }
    }).catch((error) => {
      console.log("certificateError", error);
    })


  }

  return (
    <main id="events">
      <Container className="admin_padding">
        {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "14px 0",
              alignItems: "center",
            }}
            className="table-hader-container"
          >
            <div>
              <h3 className="table-hader-name">{!isView ? (isEditMode ? 'Edit Distributor' : 'Add Distributor') : 'View Distributor'}</h3>
            </div>
            <Link to="/distributor" className="page_add_btn">
              Back
            </Link>
          </div> */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // margin: "14px 0",
            alignItems: "center",
          }}
          className="table-hader-container"
        >
          <div>
            <h3 className="table-hader-name">{!isView ? (isEditMode ? 'EDIT DISTRIBUTOR' : 'CREATE DISTRIBUTOR') : 'View Admin'}</h3>
          </div>
          <Link to="/distributor" className="page_add_btn">
            Back
          </Link>
        </div>
        <div className="admin_form">
          <div className="detail-heading">
            <h6 className="text-white">ENTER DETAILS</h6>
          </div>
          <Row className="admin_add_form">
            <Col>
              <Form noValidate onSubmit={handleSubmit}>
                {
                  (userRole == 'superAdmin') && <>

                    <Row className="mb-3">
                      <Col className="mb-3" md={6}>
                        <Form.Group controlId="validationFormik01">
                          <Form.Label>
                            Select Admin<span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Select
                            type="text"
                            name="admin"
                            value={values.admin}
                            onChange={(e) => {
                              getPartner(e.target.value)
                              setFieldValue("admin", e.target.value)
                            }}
                            isInvalid={!!errors.admin && touched.admin}
                          >
                            <option value=''>Select Admin</option>
                            {adminList && adminList.length > 0 && adminList.map((item, index) => {
                              return (
                                <option value={item?.id} >{item?.userName}</option>
                              )
                            })}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.admin}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      {!!values.admin &&
                        <Col md={6}>
                          <Form.Group controlId="validationFormik01">
                            <Form.Label>
                              Select Partner
                            </Form.Label>
                            <Form.Select
                              type="text"
                              name="partner"
                              value={values.partner}
                              onChange={(e) => {
                                setFieldValue("partner", e.target.value)
                              }}
                            >
                              <option>Select Partner</option>
                              {partnerList && partnerList.length > 0 && partnerList.map((item, index) => {
                                return (
                                  <option value={item?.id} >{item?.userName}</option>
                                )
                              })}
                            </Form.Select>

                          </Form.Group>
                        </Col>
                      }
                    </Row>
                  </>}
                <Row className="mb-3">
                  <Col className="mb-3" md={6}>
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>
                        User Name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="userName"
                        disabled={isView}
                        value={values.userName}
                        placeholder="Enter User Name"
                        onChange={handleChange}
                        isInvalid={!!errors.userName && touched.userName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.userName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>
                        Email
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        disabled={isView}
                        placeholder="Enter Email"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>
                        Phone No
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="phone"
                        placeholder="Enter Phone No"
                        disabled={isView}
                        value={values.phone}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  {
                    !isView && (<Col>
                      <Form.Group controlId="validationFormik01">
                        <Form.Label>
                          Password {!isEditMode && <span className="text-danger">*</span>}
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder="Enter Password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password && touched.password}
                        // placeholder={isEditMode ? 'Leave blank to keep unchanged' : ''}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>)
                  }
                </Row>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="footer-btns"
                >
                  {
                    !isView &&
                    <Button type="submit" className="input_file_button">
                      {isEditMode ? 'UPDATE DISTRIBUTOR' : 'SAVE DISTRIBUTOR'}
                    </Button>
                  }

                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </main>
  );
};

export default DestributorFormModel;
