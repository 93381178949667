import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../style.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import useToast from "../../../provider/Toast/useToast";
import { apiAdminConfig } from "../../../utils/api";

const GameFormModel = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const toast = useToast();
    const isEditMode = !!location?.state?.data; // Check if we are in edit mode

    const [initialValues, setInitialValues] = useState({
        description: '',
        duration: '',
        winRatio: '',
        image: '',
        name: ''
    });

    useEffect(() => {
        if (isEditMode) {
            setInitialValues(location?.state?.data);
        }
    }, [location, isEditMode]);

    const validationSchema = yup.object().shape({
        description: yup.string()
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Description is required'),
        name: yup.string()
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Name is required'),
        duration: yup.string()
            .required('Duration is required'),
        winRatio: yup.string().required('winRatio is required'),
        image: yup.string().required('Required image')
        // image: yup.mixed()
        //     .nullable()
        //     .test('fileSize', 'File too large', value => !value || (value && value.size <= 1024 * 1024))
        //     .test('fileFormat', 'Unsupported Format', value => !value || ['image/jpeg', 'image/png'].includes(value.type)),
    });

    const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,

        onSubmit: async () => {
            let formData = new FormData()
            Object.entries(values).forEach(([key, value]) => {
                if (value) formData.append(key, value);
            });
            if (isEditMode) {
                await apiAdminConfig.put(`games/update/${location?.state?.data?.id}`, formData).then((response) => {
                    if (response?.status === 200) {
                        navigate('/game');
                        toast({
                            message: 'Game updated successfully',
                            bg: 'success'
                        });
                    } else {
                        toast({
                            message: response?.data?.message,
                            bg: 'danger'
                        });
                    }
                }).catch((error) => {
                    const { response } = error;
                    toast({
                        message: response?.data?.message,
                        bg: "danger",
                    });
                });

            } else {

                await apiAdminConfig.post(`games/create`, formData).then((response) => {
                    if (response?.status === 201) {
                        navigate('/game');
                        toast({
                            message: 'Game registered successfully',
                            bg: 'success'
                        });
                    } else {
                        toast({
                            message: response?.data?.message,
                            bg: 'danger'
                        });
                    }
                }).catch((error) => {
                    const { response } = error;
                    toast({
                        message: response?.data?.message,
                        bg: "danger",
                    });
                });
            }
        },
    });

    return (
        <main id="events">
            <Container className="admin_padding">
                <div className="admin_add_form">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "14px 0",
                            alignItems: "center",
                        }}
                        className="table-hader-container"
                    >
                        <div>
                            <h3 className="table-hader-name">{isEditMode ? 'Edit Game' : 'Add Game'}</h3>
                        </div>
                        <Link to="/game" className="page_add_btn">
                            Back
                        </Link>
                    </div>
                    <Row>
                        <Col>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Col md={6}>
                                        <Form.Group controlId="validationFormik01">
                                            <Form.Label>
                                                Name<span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                placeholder="Enter Game Name"
                                                isInvalid={!!errors.name && touched.name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="validationFormik01">
                                            <Form.Label>
                                                Description<span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="description"
                                                placeholder="Enter Description"
                                                value={values.description}
                                                onChange={handleChange}
                                                isInvalid={!!errors.description && touched.description}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.description}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col>
                                        <Form.Group controlId="validationFormik01">
                                            <Form.Label>
                                                Duration<span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="duration"
                                                placeholder="Enter Duration"
                                                value={values.duration}
                                                onChange={handleChange}
                                                isInvalid={!!errors.duration && touched.duration}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.duration}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="validationFormik01">
                                            <Form.Label>
                                                Win Ratio<span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="winRatio"
                                                placeholder="Enter Win Ratio"
                                                value={values.winRatio}
                                                onChange={handleChange}
                                                isInvalid={!!errors.winRatio && touched.winRatio}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.winRatio}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>


                                </Row>

                                <Col md={6} className="mb-3">
                                    <Form.Group controlId="validationFormik04">
                                        <Form.Label>
                                            Image<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="image"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={(e) => {
                                                const file = e.target.files ? e.target.files[0] : null;
                                                setFieldValue("image", file)
                                            }}
                                            isInvalid={!!errors.image && touched.image}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.image}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>


                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                    className="footer-btns"
                                >
                                    <Button type="submit" className="input_file_button">
                                        {isEditMode ? 'Update' : 'Save'}
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Container>
        </main>
    );
};

export default GameFormModel;


