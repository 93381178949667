import React, { useEffect } from "react";
import AdminRoute from "./AdminRoute";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserToken, removeUserData } from "../utils/cookie";

function MyRoutes() {

    const navigate = useNavigate();
    const location = useLocation();

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };

    const isValidate = (tokenStorage, decodedJwt) => {
        if (!tokenStorage || (decodedJwt?.exp * 1000 < Date.now())) {
            return true
        }
        return false
    };

    useEffect(() => {
        const tokenStorage = localStorage.getItem('token');
        const decodedJwt = parseJwt(tokenStorage ?? '');
        if (isValidate(tokenStorage, decodedJwt)) {
            removeUserData();
            navigate('/login');
        }
    }, [])

    return (
        <>
            <AdminRoute />
        </>
    );
}

export default MyRoutes;