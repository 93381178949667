import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../style.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import useToast from "../../../provider/Toast/useToast";
import { apiAdminConfig } from "../../../utils/api";
import { getRole } from "../../../utils/cookie";

const SubDestributorFormModel = () => {

  const location = useLocation();
  const isEditMode = !!location?.state?.data;
  const params = useParams()
  const isView = params.id == "view" ? true : false
  const [userRole, setUserRole] = React.useState(getRole())

  const [initialValues, setInitialValues] = useState({
    id: '',
    userName: '',
    email: '',
    password: '',
    phone: '',
    ...((userRole === 'superAdmin') && { admin: '', partner: '', distributor: '' }),
  })
  const [adminList, setAdminList] = useState([])
  const [partnerList, setPartnerList] = useState([])
  const [disributorList, setDistributorList] = useState([])

  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {

    if (isEditMode || isView) {
      if (userRole == 'superAdmin') {
        getPartner(location?.state?.data?.admin?.id)
        getDistributor(location?.state?.data?.partner?.id ? location?.state?.data?.partner?.id : location?.state?.data?.admin?.id)
      } else {
        getDistributor(location?.state?.data?.admin?.id)
      }
      setInitialValues({
        ...location?.state?.data,
        distributor: location?.state?.data?.distributor?.id,
        admin: location?.state?.data?.admin?.id,
        partner: location?.state?.data?.partner?.id
      });

    }
  }, [location, isEditMode])


  React.useEffect(() => {
    const data = localStorage.getItem('userDetail')
    const userData = JSON.parse(data);

    if ((userData?.role == 'admin' || userData?.role == 'partner')) {
      getDistributor();
    }
    if (userData?.role == 'superAdmin') {
      getAdmin();
    }
  }, []);

  const getAdmin = async () => {
    await apiAdminConfig.get('users/get?role=admin').then((response) => {
      console.log("response?.data", response?.data.results)
      if (response?.data?.results?.length > 0) {
        setAdminList(response?.data?.results)
      }
    }).catch((error) => {
      console.log("certificateError", error);
    })
  };




  const validationSchema = yup.object().shape({
    userName: yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Name is required'),
    password: isEditMode
      ? yup.string().nullable()
      : yup.string()
        .min(4, 'Minimum 4 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    ...((userRole === 'superAdmin') && { admin: yup.string().required("Select Admin is required") }),
    ...((userRole === 'admin' || userRole === 'partner') && {
      distributor: yup.string().required("Select Distributor is required"),
      ...((userRole === 'superAdmin') && { distributor: yup.string().required("Select Distributor is required"), admin: yup.string().required("Select Admin is required") }),

    }),
  });

  // const validationSchema = userRole === 'admin'
  //   ? baseValidationSchema.concat(yup.object().shape({
  //     distributor: yup.string().required("Select Distributor is required"),
  //   }))
  //   : baseValidationSchema;

  const { handleSubmit, handleChange, setFieldValue, values, errors, touched, validateForm } =
    useFormik({
      initialValues: initialValues,
      validationSchema,
      enableReinitialize: true,

      onSubmit: async () => {
        values['phone'] = values.phone.toString();

        if (!values?.partner) delete values.partner

        if (isEditMode) {

          await apiAdminConfig.put(`users/update/${location?.state?.data?.id}`, values).then((response) => {
            if (response?.status === 200) {
              navigate('/sub-distributor');
              toast({
                message: 'Sub Distributor updated successfully',
                bg: 'success'
              });
            } else {
              toast({
                message: response?.data?.message,
                bg: 'danger'
              });
            }
          }).catch((error) => {
            const { response } = error;
            toast({
              message: response?.data?.message,
              bg: "danger",
            });
          });

        } else {

          await apiAdminConfig.post(`users/add-sub-distributor`, values).then((response) => {
            console.log("cfff", response)
            if (response?.status == 201) {
              navigate('/sub-distributor')
              toast({
                message: response?.data?.message,
                bg: 'success'
              });
            } else {
              toast({
                message: response?.data?.message,
                bg: 'danger'
              });
            }
          }).catch((error) => {
            console.log("eventError", error)
            const { response } = error
            toast({
              message: response?.data?.message,
              bg: "danger",
            });
          })
        }
      },
    });

  const getDistributor = async (id = '') => {
    const parentId = (id == "" && !!values.admin) ? values.admin : id
    await apiAdminConfig.get(`users/list?userBy=distributor&userId=${parentId}`).then((response) => {
      if (response?.data?.length > 0) {
        setDistributorList(response?.data)
      }
    }).catch((error) => {
      console.log("certificateError", error);
    })
  };

  const getPartner = async (id) => {
    setFieldValue("partner", '')
    setFieldValue("distributor", '')
    setPartnerList([])
    setDistributorList([])


    await apiAdminConfig.get(`users/list?userBy=partner&userId=${id}`).then((response) => {
      console.log("+-----", response)
      if (response?.data?.length > 0) {
        setPartnerList(response?.data)
      } else {
        setPartnerList([])
      }
    }).catch((error) => {
      console.log("certificateError", error);
    })

    await apiAdminConfig.get(`users/list?userBy=distributor&userId=${id}`).then((response) => {
      console.log("+-----", response)
      if (response?.data?.length > 0) {
        setDistributorList(response?.data)
      } else {
        setDistributorList([])
      }
    }).catch((error) => {
      console.log("certificateError", error);
    })

  }



  return (
    <main id="events">
      <Container className="admin_padding">
        {/* <div className="admin_add_form">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "14px 0",
              alignItems: "center",
            }}
            className="table-hader-container"
          >
            <div>
              <h3 className="table-hader-name">{!isView ? (isEditMode ? "Edit" : "Add") : "View"} Sub Distributor</h3>
            </div>
            <Link to="/sub-distributor" className="page_add_btn">
              Back
            </Link>
          </div> */}
           <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // margin: "14px 0",
              alignItems: "center",
            }}
            className="table-hader-container"
          >
            <div>
              <h3 className="table-hader-name">{!isView ? (isEditMode ? 'EDIT SUB DISTRIBUTOR' : 'CREATE SUB DISTRIBUTOR') : 'View Admin'}</h3>
            </div>
            <Link to="/sub-distributor" className="page_add_btn">
              Back
            </Link>
          </div>
        <div className="admin_form">
        <div className="detail-heading">
              <h6 className="text-white">ENTER DETAILS</h6>
            </div>
          <Row className="admin_add_form">
            <Col>
              <Form noValidate onSubmit={handleSubmit}>
                {
                  (userRole == 'superAdmin') && <>

                    <Row>
                      <Col md={6} className="mb-3">
                        <Form.Group controlId="validationFormik01">
                          <Form.Label>
                            Select Admin<span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Select
                            type="text"
                            name="admin"
                            value={values.admin}
                            onChange={(e) => {
                              getPartner(e.target.value)
                              setFieldValue("admin", e.target.value)
                            }}
                            isInvalid={!!errors.admin && touched.admin}
                          >

                            <option value=''>Select Admin</option>
                            {adminList && adminList.length > 0 && adminList.map((item, index) => {
                              return (
                                <option value={item?.id} >{item?.userName}</option>
                              )
                            })}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.admin}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      {!!values.admin &&
                        <Col md={6} className="mb-3">
                          <Form.Group controlId="validationFormik01">
                            <Form.Label>
                              Select Partner
                            </Form.Label>
                            <Form.Select
                              type="text"
                              name="partner"
                              value={values.partner}
                              onChange={(e) => {
                                setFieldValue("partner", e.target.value)
                                getDistributor(e.target.value)
                                setFieldValue("distributor", '')
                                setDistributorList([])
                              }}
                            >
                              <option value=''>Select Partner</option>
                              {partnerList && partnerList.length > 0 && partnerList.map((item, index) => {
                                return (
                                  <option value={item?.id} >{item?.userName}</option>
                                )
                              })}
                            </Form.Select>

                          </Form.Group>
                        </Col>
                      }
                    </Row>
                  </>}
                {
                  (userRole == 'admin' || userRole == 'partner' || !!values.admin) && <>

                    <Row className="mb-3">
                      <Col md={6} >
                        <Form.Group controlId="validationFormik01">
                          <Form.Label>
                            Select Distributor<span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Select
                            type="text"
                            name="distributor"
                            value={values.distributor}
                            disabled={isView}
                            onChange={(e) => {
                              setFieldValue("distributor", e.target.value)
                            }}
                            isInvalid={!!errors.distributor && errors.distributor}
                          >

                            <option value=''>Select Distributor</option>
                            {disributorList && disributorList.length > 0 && disributorList.map((item, index) => {
                              return (
                                <option value={item?.id} >{item?.userName}</option>
                              )
                            })}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.distributor}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                    </Row>
                  </>}

                <Row >
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>
                        User Name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="userName"
                        placeholder="Enter User Name"
                        value={values.userName}
                        disabled={isView}
                        onChange={handleChange}
                        isInvalid={!!errors.userName && touched.userName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.userName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>
                        Email
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Enter Email"
                        disabled={isView}
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                </Row>
                <Row className="mb-3">
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>
                        Phone No
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="phone"
                        placeholder="Enter Phone No"
                        disabled={isView}
                        value={values.phone}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  {
                    !isView && (<Col className="mb-3" md={6}>
                      <Form.Group controlId="validationFormik01">
                        <Form.Label>
                          Password{!isEditMode && <span className="text-danger">*</span>}
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder="Enter Password"
                          disabled={isView}
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password && touched.password}
                        // placeholder={isEditMode ? 'Leave blank to keep unchanged' : ''}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>)
                  }
                </Row>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="footer-btns"
                >
                  {!isView &&
                    <Button type="submit" className="input_file_button">
                      {isEditMode ? 'UPDATE SUB DISTRIBUTOR' : 'SAVE SUB DISTRIBUTOR'}
                    </Button>
                  }
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </main>
  );
};

export default SubDestributorFormModel;
