import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../style.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import useToast from "../../../provider/Toast/useToast";
import { apiAdminConfig } from "../../../utils/api";
import { getRole } from "../../../utils/cookie";

const AgentFormModel = () => {

    const location = useLocation();
    const isEditMode = !!location?.state?.data;
    const params = useParams()
    const isView = params.id == "view" ? true : false


    const [disributorList, setDistributorList] = useState([])
    const [subDisributorList, setSubDistributorList] = useState([])
    const [userRole, setUserRole] = React.useState(getRole())
    const toast = useToast();
    const navigate = useNavigate();
    const [adminList, setAdminList] = useState([])
    const [partnerList, setPartnerList] = useState([])

    const [initialValues, setInitialValues] = useState({
        id: '',
        userName: '',
        email: '',
        password: '',
        phone: '',
        ...((userRole === 'admin' || userRole === 'partner') && { distributor: '', subDistributor: '' }),
        ...((userRole === 'distributor') && { subDistributor: '' }),
        ...((userRole === 'superAdmin') && { admin: '', partner: '', distributor: '', subDistributor: '' }),
    })

    useEffect(() => {
        if (isEditMode || isView) {
            if (userRole == 'admin' || userRole == 'partner') {
                getSubDistributor(location?.state?.data?.distributor?.id)
            } if (userRole == 'superAdmin') {
                getPartner(location?.state?.data?.admin?.id)
                getDistributor(location?.state?.data?.partner?.id ? location?.state?.data?.partner?.id : location?.state?.data?.admin?.id)
                getSubDistributor(location?.state?.data?.distributor?.id)
            }
            selectDistibutor(location?.state?.data?.distributor.id)
            setInitialValues({
                ...location?.state?.data,
                distributor: location?.state?.data?.distributor?.id,
                admin: location?.state?.data?.admin?.id,
                partner: location?.state?.data?.partner?.id,
                subDistributor: location?.state?.data?.subDistributor.id,
            });
        }

    }, [location, isEditMode])

    const getSubDistributor = async (id) => {
        await apiAdminConfig.get(`users/list?userBy=subDistributor&userId=${id}`).then((response) => {
            if (response?.data?.length > 0) {
                setSubDistributorList(response?.data)
            } else {
                setSubDistributorList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };

    React.useEffect(() => {
        const data = localStorage.getItem('userDetail')
        const userData = JSON.parse(data)
        if (userData?.role == 'admin' || userData?.role == 'partner') {
            getDistributor();
        }
        if (userData?.role == 'distributor') {
            getSubDistributor('');
        }
        if (userData?.role == 'superAdmin') {
            getAdmin();
        }
    }, []);


    const getAdmin = async () => {
        await apiAdminConfig.get('users/get?role=admin').then((response) => {
            console.log("response?.data", response?.data.results)
            if (response?.data?.results?.length > 0) {
                setAdminList(response?.data?.results)
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })
    };


    const validationSchema = yup.object().shape({
        userName: yup.string()
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('User Name is required'),
        password: isEditMode
            ? yup.string().nullable()
            : yup.string()
                .min(4, 'Minimum 4 symbols')
                .max(50, 'Maximum 50 symbols')
                .required('Password is required'),
        ...((userRole === 'admin' || userRole === 'partner') && { distributor: yup.string().required("Select Distributor is required"), subDistributor: yup.string().required("Select Sub Distributor is required") }),
        ...((userRole === 'distributor') && { subDistributor: yup.string().required("Select Sub Distributor is required") }),
        ...((userRole === 'superAdmin') && { distributor: yup.string().required("Select Distributor is required"), subDistributor: yup.string().required("Select Sub Distributor is required"), admin: yup.string().required("Select Admin is required") }),

    });


    // const validationSchema = userRole === 'admin'
    //     ? baseValidationSchema.concat(yup.object().shape({
    //         distributor: yup.string().required("Select Distributor is required"),
    //         subDistributor: yup.string().required("Select Sub Distributor is required"),
    //     }))
    //     : baseValidationSchema;



    const { handleSubmit, handleChange, setFieldValue, values, errors, touched, validateForm } =
        useFormik({
            initialValues: initialValues,
            validationSchema,
            enableReinitialize: true,

            onSubmit: async () => {
                values['phone'] = values.phone.toString();

                if (!values.partner) delete values.partner

                if (isEditMode) {
                    await apiAdminConfig.put(`users/update/${location?.state?.data?.id}`, values).then((response) => {
                        if (response?.status === 200) {
                            navigate('/agent');
                            toast({
                                message: 'Agent updated successfully',
                                bg: 'success'
                            });
                        } else {
                            toast({
                                message: response?.data?.message,
                                bg: 'danger'
                            });
                        }
                    }).catch((error) => {
                        const { response } = error;
                        toast({
                            message: response?.data?.message,
                            bg: "danger",
                        });
                    });

                } else {

                    await apiAdminConfig.post(`users/add-agent`, values).then((response) => {
                        console.log("cfff", response)
                        if (response?.status == 201) {
                            navigate('/agent')
                            toast({
                                message: 'Agent added successfully',
                                bg: 'success'
                            });
                        } else {
                            toast({
                                message: response?.data?.message,
                                bg: 'danger'
                            });
                        }
                    }).catch((error) => {
                        console.log("eventError", error)
                        const { response } = error
                        toast({
                            message: response?.data?.message,
                            bg: "danger",
                        });
                    })
                }

            },
        });

        const getDistributor = async (id = '') => {
            const parentId = (id == ""  && !!values.admin)? values.admin : id 
              await apiAdminConfig.get(`users/list?userBy=distributor&userId=${parentId}`).then((response) => {
                  if (response?.data?.length > 0) {
                      setDistributorList(response?.data)
                  } else {
                      setDistributorList([])
                  }
              }).catch((error) => {
                  console.log("certificateError", error);
              })
          };

    const selectDistibutor = async (id) => {
        await apiAdminConfig.get(`users/list?userBy=subDistributor&userId=${id}`).then((response) => {
            console.log("+-----", response)
            if (response?.data?.length > 0) {
                setSubDistributorList(response?.data)
            } else {
                setSubDistributorList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })

    }

    const getPartner = async (id) => {
        setFieldValue("partner", '')
        setFieldValue("distributor", '')
        setPartnerList([])
        setDistributorList([])


        await apiAdminConfig.get(`users/list?userBy=partner&userId=${id}`).then((response) => {
            console.log("+-----", response)
            if (response?.data?.length > 0) {
                setPartnerList(response?.data)
            } else {
                setPartnerList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })

        await apiAdminConfig.get(`users/list?userBy=distributor&userId=${id}`).then((response) => {
            console.log("+-----", response)
            if (response?.data?.length > 0) {
                setDistributorList(response?.data)
            } else {
                setDistributorList([])
            }
        }).catch((error) => {
            console.log("certificateError", error);
        })

    }


    return (
        <main id="events">
            <Container className="admin_padding">
                {/* <div className="admin_add_form">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "14px 0",
                            alignItems: "center",
                        }}
                        className="table-hader-container"
                    >
                        <div>
                            <h3 className="table-hader-name">{!isView ? (isEditMode ? "Edit" : "Add") : "View"} Agent</h3>
                        </div>
                        <Link to="/agent" className="page_add_btn">
                            Back
                        </Link>
                    </div> */}
                    <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // margin: "14px 0",
            alignItems: "center",
          }}
          className="table-hader-container"
        >
          <div>
            <h3 className="table-hader-name">{!isView ? (isEditMode ? 'EDIT AGENT' : 'CREATE AGENT') : 'View Admin'}</h3>
          </div>
          <Link to="/agent" className="page_add_btn">
            Back
          </Link>
        </div>
        <div className="admin_form">
          <div className="detail-heading">
            <h6 className="text-white">ENTER DETAILS</h6>
          </div>
                    <Row className="admin_add_form">
                    {
                        (userRole == 'superAdmin') && <>

                            <Row>
                                <Col md={6} className="mb-3">
                                    <Form.Group controlId="validationFormik01">
                                        <Form.Label>
                                            Select Admin<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Select
                                            type="text"
                                            name="admin"
                                            value={values.admin}
                                            onChange={(e) => {
                                                getPartner(e.target.value)
                                                setFieldValue("admin", e.target.value)
                                            }}
                                            isInvalid={!!errors.admin && touched.admin}
                                        >

                                            <option value=''>Select Admin</option>
                                            {adminList && adminList.length > 0 && adminList.map((item, index) => {
                                                return (
                                                    <option value={item?.id} >{item?.userName}</option>
                                                )
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.admin}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                {!!values.admin &&
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="validationFormik01">
                                            <Form.Label>
                                                Select Partner
                                            </Form.Label>
                                            <Form.Select
                                                type="text"
                                                name="partner"
                                                value={values.partner}
                                                onChange={(e) => {
                                                    setFieldValue("partner", e.target.value)
                                                    getDistributor(e.target.value)
                                                    setFieldValue("distributor", '')
                                                    setDistributorList([])

                                                }}
                                            >
                                                <option value=''>Select Partner</option>
                                                {partnerList && partnerList.length > 0 && partnerList.map((item, index) => {
                                                    return (
                                                        <option value={item?.id} >{item?.userName}</option>
                                                    )
                                                })}
                                            </Form.Select>

                                        </Form.Group>
                                    </Col>
                                }
                            </Row>
                        </>}
                    <Row >
                        {
                            (userRole == 'admin' || userRole === 'partner' || !!values.admin) && <>
                                <Col className="mb-3">
                                    <Form.Group controlId="validationFormik01">
                                        <Form.Label>
                                            Select Distributor<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Select
                                            type="text"
                                            name="distributor"
                                            disabled={isView}
                                            value={values.distributor}
                                            onChange={(e) => {
                                                setFieldValue("distributor", e.target.value)
                                                if (e.target.value) {
                                                    selectDistibutor(e.target.value)
                                                } else {
                                                    setSubDistributorList([])
                                                    setFieldValue("subDistributor", '')
                                                }
                                            }}
                                            isInvalid={!!errors.distributor && touched.distributor}
                                        >
                                            <option value=''>Select Distributor</option>
                                            {disributorList && disributorList.length > 0 && disributorList.map((item, index) => {
                                                return (
                                                    <option value={item?.id} >{item?.userName}</option>
                                                )
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.distributor}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </>

                        }

                        {
                            (userRole === 'distributor' || !!values.distributor) &&

                            <Col className="mb-3" md={6}>
                                <Form.Group controlId="validationFormik01">
                                    <Form.Label>
                                        Select Sub Distributor<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Select
                                        type="text"
                                        name="subDistributor"
                                        value={values.subDistributor}
                                        disabled={isView}
                                        onChange={(e) => {
                                            setFieldValue("subDistributor", e.target.value)
                                        }}
                                        isInvalid={!!errors.subDistributor && touched.subDistributor}
                                    >
                                        <option value=''>Select Sub Distributor</option>
                                        {subDisributorList && subDisributorList.length > 0 && subDisributorList.map((item, index) => {
                                            return (
                                                <option value={item?.id} >{item?.userName}</option>
                                            )
                                        })}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.subDistributor}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        }
                    </Row>
                    <Row>
                        <Col>
                            <Form noValidate onSubmit={handleSubmit}>

                                <Row>
                                    <Col className="mb-3" md={6}>
                                        <Form.Group controlId="validationFormik01">
                                            <Form.Label>
                                                User Name<span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="userName"
                                                placeholder="Enter User Name"
                                                value={values.userName}
                                                disabled={isView}
                                                onChange={handleChange}
                                                isInvalid={!!errors.userName && touched.userName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.userName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="validationFormik01">
                                            <Form.Label>
                                                Email
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="email"
                                                placeholder="Enter Email"
                                                disabled={isView}
                                                value={values.email}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>


                                <Row >
                                    <Col  className="mb-3" md={6}>
                                        <Form.Group controlId="validationFormik01">
                                            <Form.Label>
                                                Phone No
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="phone"
                                                placeholder="Enter Phone No"
                                                disabled={isView}
                                                value={values.phone}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>

                                    {
                                        !isView && <Col  className="mb-3" md={6}>
                                            <Form.Group controlId="validationFormik01">
                                                <Form.Label>
                                                    Password {!isEditMode && <span className="text-danger">*</span>}
                                                </Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    name="password"
                                                    placeholder="Enter Password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    isInvalid={
                                                        !!errors.password && touched.password
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.password}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    }
                                </Row>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                    className="footer-btns"
                                >
                                    {
                                        !isView &&
                                        <Button type="submit" className="input_file_button">
                                            {isEditMode ? 'UPDATE AGENT' : 'SAVE AGENT'}
                                        </Button>
                                    }

                                </div>
                            </Form>
                        </Col>
                    </Row>
                    </Row>
                </div>
            </Container>
        </main>
    );
};

export default AgentFormModel;
