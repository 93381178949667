import React from 'react';
import { useNavigate } from 'react-router-dom';

const Error = () => {
  const navigate = useNavigate();
  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        backgroundImage: 'url(/asset/errorback.jpeg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        color: '#fff',
        textAlign: 'center',
        padding: '20px'
      }}
    >
      <div>
        <p style={{ fontSize: '12rem', margin: '0', fontWeight: 700 }}>404</p>
        <h1 className="fw-bolder fs-4x text-white" style={{ marginBottom: '30px' }}>
          OOPS...
        </h1>
        <div className="fw-bold  text-white" style={{ marginBottom: '30px' }}>
          We're sorry, but <br /> something went wrong.
        </div>
        <button
          onClick={handleGoHome}
          style={{
            padding: '10px 20px',
            fontSize: '1rem',
            cursor: 'pointer',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '5px'
          }}
        >
          Go Home
        </button>
      </div>
    </div>
  );
};

export { Error };
