import React from 'react';
import MyRoutes from './Routes/index';
import ScrollToTop from './ScrollToTop';


function App() {
    return (
        <>
            <ScrollToTop />
            <MyRoutes />

        </>
    );
};

export default App;
